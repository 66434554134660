import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import { Glossary } from '../components/glossary';
import { refreshSessionId } from '../utils/session_id';

import { RenderHead } from "../components/head";
export const Head = () => RenderHead({ title: "Glossary" });


const GlossaryHome = ({ data, location }) => {

    const glossaryData = data.allInternalGlossaryJson.edges.map(({node}) => node);
    
    useEffect(() => { refreshSessionId(); }, []);

    return (
        <Layout
          location={ location } >          

          { Glossary({ data: glossaryData }) }
          
        </Layout>
    );
};


export default GlossaryHome;



// allInternalBriefs {
export const pageQuery = graphql`
  query {
    allInternalGlossaryJson {
      edges {
        node {
          id 
          title_unique 
          body 
          created
          question
          title 
        }
      }
    }
  }
`;
