import React from 'react';
import { colors } from '../colors';

export const hr = () =>
<hr
  style={{
        margin: 0,
        width: `100%`,
        color: colors.darkerGrey
  }} />;
