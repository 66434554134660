import React from 'react';
import { Link } from 'gatsby';
import { colors } from './colors';
import { hr } from './glossary/hr';
import { groupBy, reduceKv, sort, threadLast } from '../utils/functional';

import * as styles from './glossary.module.css';



const Header = () => {
    return (
        <div
          style={{
              margin: `120px 0 0`,
              fontFamily: `"Overpass", sans-serif`,
              fontSize: `40px`,
              fontWeight: `bold`,
              color: colors.black
          }} >
          Glossary
        </div>
    );
};

const LetterIndexLink = (letter) => {
    return (
        <a
          key={ Math.random() }
          href={ `#` + letter }
          style={{
              margin: `0 20px 0 0`,
              fontFamily: `"Overpass", sans-serif`,
              fontSize: `18px`,
              fontWeight: `bold`,
              color: colors.green,
              textDecoration: `none`
          }} >
          { letter }
        </a>
    );
};

const LetterIndex = (groups) => {

    const letters = Object.keys(groups).sort().map(c => c.toUpperCase());
    //const letters = 'abcdefghijklmnopqrstuvwxyz'.split('').map(c => c.toUpperCase());
    
    return (
        <div
          style={{
              margin: `50px 0 0`,
              width: `100%`,
              display: `flex`,
              flexDirection: `column`,
              justifyContent: `flex-start`,
              alignItems: `center`
          }} >

          { hr() }

          <div
            style={{
                margin: `20px 0 20px 20px`,
                display: `flex`,
                flexDirection: `row`,
                justifyContent: `center`,
                alignItems: `center`,
                flexWrap: `wrap`
            }} >

            { letters.map(LetterIndexLink) }
            
          </div>

          { hr() }
          
        </div>
    );
};

const groupByFirstLetter = (data) => {
    const firstLetter = ({ title_unique }) => title_unique[0];
    const rSort = (acc, k, v) => {
        const nv = sort((a,b) => a.title_unique - b.title_unique, v);
        acc[k] = nv;
        return acc;
    };
    return threadLast(data,
                      [groupBy, firstLetter],
                      [reduceKv, rSort, {}]);
};

const Listing = ({ title, title_unique }) => {
    return (
        <Link
          key={ Math.random() }
          to={ title_unique }
          style={{
              margin: `15px 0 0`,
              textDecoration: `none`
          }} >

          <div
            style={{
                fontSize: `20px`,
                color: colors.black
            }} >
            { title }
          </div>
          
        </Link>
    );
};

const AlphaSection = ({ letter, datums }) => {
    return (
        <div
          key={ Math.random() }
          style={{
              margin: `90px 0 0`
          }} >
          
          <div
            id={ letter.toUpperCase() }
            style={{
                fontFamily: `"Overpass", sans-serif`,
                fontSize: `46px`,
                fontWeight: `bold`,
                color: colors.black
            }} >
            { letter.toUpperCase() }
          </div>
          
          <div
            style={{
                margin: `10px 0 0`,
                fontFamily: `"Overpass", sans-serif`,
                fontSize: `24px`,
                fontWeight: `bold`,
                color: colors.black
            }} >
            { datums.map( Listing ) }
          </div>
          
        </div>
    );
};

const AllListings = (groups) => {
    
    var sections = [];
    const ks = Object.keys(groups).sort();
    for ( const k of ks ) {
        const letter = k;
        const datums = groups[k];
        sections.push( AlphaSection({ letter, datums }) );
    }
    
    return (
        <div
          className={ styles.sectionContainer } >
          { sections }
        </div>
    );
};

export const Glossary = ({ data }) => {

    const groups = groupByFirstLetter(data);
    
    return (
        <div
          style={{
              margin: `0 0 200px`,
              display: `flex`,
              flexDirection: `column`,
              justifyContent: `flex-start`,
              alignItems: `center`
          }} >
          
          { Header() }

          { LetterIndex(groups) }
          
          { AllListings(groups) }

        </div>
    );
};
